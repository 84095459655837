const loadIcons = require('loadicons');

function isElementInViewport (el) {
    var rect = el[0].getBoundingClientRect();
    return (rect.top>-1 && rect.bottom <= $(window).height());
}

window.preloadSvgIconsURLS.filter(url => url.trim() != "").forEach(loadIcons);

const iconsAreVisible = Array.from(document.querySelectorAll("a svg.icon")).some(icon => {
  const rect = icon.getBoundingClientRect();
  return (rect.top > -1 && rect.bottom < window.innerHeight);
});
if (iconsAreVisible) {
  window.svgIconsURLs.filter(url => url.trim() != "").forEach(loadIcons);
} else {
  window.delayedScriptLoaders = window.delayedScriptLoaders || [];
  window.delayedScriptLoaders.push(function() {
	window.svgIconsURLs.filter(url => url.trim() != "").forEach(loadIcons);
  });
}
